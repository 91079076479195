body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
body, button, input, select, textarea { font:12px/1.5tahoma, arial, \5b8b\4f53; }
h1, h2, h3, h4, h5, h6{ font-size:100%; }
address, cite, dfn, em, var { font-style:normal; }
code, kbd, pre, samp { font-family:couriernew, courier, monospace; }
small{ font-size:12px; }
ul, ol { list-style:none; }
a { text-decoration:none; }
a:hover { text-decoration:underline; }
sup { vertical-align:text-top; }
sub{ vertical-align:text-bottom; }
legend { color:#000; }
fieldset, img { border:0; }
button, input, select, textarea { font-size:100%; }
table { border-collapse:collapse; border-spacing:0; }



@-webkit-keyframes fadeIn{
  0%{
    opacity: 0.6;
  }
  25%{
    opacity: 0.7;
  } 
  50%{
    opacity: 0.8;
  }
  75%{
    opacity: 0.9;
  }
  100%{
    opacity: 1;
  }
}



@keyframes fadeIn{
  0%{
    opacity: 0.6;
  }
  25%{
    opacity: 0.7;
  } 
  50%{
    opacity: 0.8;
  }
  75%{
    opacity: 0.9;
  }
  100%{
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut{
  0%{
    opacity: 1;
  }
  25%{
    opacity: 0.75;
  } 
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.25;
  }
  100%{
    opacity: 0;
    display: none;
  }
}
@keyframes fadeOut{
  0%{
    opacity: 1;
  }
  25%{
    opacity: 0.75;
  } 
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.25;
  }
  100%{
    opacity: 0;
    display: none;
  }
}